import { createSelector } from 'reselect';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { columnsGridSelector } from './gridList';
import { postman } from '../utils/postman';
import { RETURN_COST_TYPE, SOLD_TO_TYPE } from '../constants/columnTypes';
import list from '../containers/customGrid/list';

//*  TYPES  *//
const GET_REPRESENTATIONS_REQUEST = 'GET_REPRESENTATIONS_REQUEST';
const GET_REPRESENTATIONS_SUCCESS = 'GET_REPRESENTATIONS_SUCCESS';
const GET_REPRESENTATIONS_ERROR = 'GET_REPRESENTATIONS_ERROR';

const SAVE_REPRESENTATION_REQUEST = 'SAVE_REPRESENTATION_REQUEST';
const SAVE_REPRESENTATION_SUCCESS = 'SAVE_REPRESENTATION_SUCCESS';
const SAVE_REPRESENTATION_ERROR = 'SAVE_REPRESENTATION_ERROR';

const EDIT_REPRESENTATION_REQUEST = 'EDIT_REPRESENTATION_REQUEST';
const EDIT_REPRESENTATION_SUCCESS = 'EDIT_REPRESENTATION_SUCCESS';
const EDIT_REPRESENTATION_ERROR = 'EDIT_REPRESENTATION_ERROR';

const DELETE_REPRESENTATION_REQUEST = 'DELETE_REPRESENTATION_REQUEST';
const DELETE_REPRESENTATION_SUCCESS = 'DELETE_REPRESENTATION_SUCCESS';
const DELETE_REPRESENTATION_ERROR = 'DELETE_REPRESENTATION_REQUEST';

const SET_REPRESENTATION = 'SET_REPRESENTATION';

export const RESET_REPRESENTATION = 'RESET_REPRESENTATION';

const REPRESENTATION_KEY = 'representation';
const CURRENT_USER_NAME = 'currentUserName';

const CHANGE_IS_OPEN = 'CHANGE_IS_OPEN';

//*  INITIAL STATE  *//

const initial = {
    list: {},
    representation: JSON.parse(localStorage.getItem(REPRESENTATION_KEY)) ? JSON.parse(localStorage.getItem(REPRESENTATION_KEY))[localStorage.getItem(CURRENT_USER_NAME)] : {},
    open: false,
};



//*  REDUCER  *//

export default (state = initial, { type, payload = {} }) => {
    const { gridName, name, value, oldName} = payload;

    switch (type) {
        case GET_REPRESENTATIONS_SUCCESS:

            return {
                ...state,
                list: payload,
            };
        case SET_REPRESENTATION:
            return {
                ...state,
                representation: {
                    ...state.representation,
                    [gridName]: {value, name},
                },
            };
        case RESET_REPRESENTATION:
            return {
                ...state,
                representation: JSON.parse(localStorage.getItem(REPRESENTATION_KEY)) ? JSON.parse(localStorage.getItem(REPRESENTATION_KEY))[localStorage.getItem(CURRENT_USER_NAME)] : {},
            }
        case EDIT_REPRESENTATION_SUCCESS:
            return {
                ...state
            }
        case SAVE_REPRESENTATION_SUCCESS:
        case DELETE_REPRESENTATION_SUCCESS:
        case CHANGE_IS_OPEN:
            return {
                ...state,
                open: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getRepresentationsRequest = payload => {
    return {
        type: GET_REPRESENTATIONS_REQUEST,
        payload,
    };
};

export const saveRepresentationRequest = payload => {
    return {
        type: SAVE_REPRESENTATION_REQUEST,
        payload,
    };
};

export const editRepresentationRequest = payload => {
    return {
        type: EDIT_REPRESENTATION_REQUEST,
        payload,
    };
};

export const setRepresentationRequest = payload => {
    return {
        type: SET_REPRESENTATION,
        payload,
    };
};

export const deleteRepresentationRequest = payload => {
    return {
        type: DELETE_REPRESENTATION_REQUEST,
        payload,
    };
};

export const changeIsOpen = payload => {
    return {
        type: CHANGE_IS_OPEN,
        payload,
    };
};

export const resetRepresentation = () => {
    return {
        type: RESET_REPRESENTATION
    }
}

//*  SELECTORS *//

export const stateSelector = state => state.representations;


export const openSelector = createSelector(
    stateSelector,
    state => state.open,
);

export const representationsSelector = createSelector(
    stateSelector,
    state => state.list,
);

export const representationNameSelector = createSelector(
    [stateSelector, (state, name) => name, state => representationsSelector(state) ],
    (state, gridName, list) => {
        const name = state.representation ? state.representation[gridName] ?  state.representation[gridName] : null : null;
        return name && list && Object.keys(list).length !== 0 ? list.find(el => el.id === name.value) ? name ? name.name : null : null : null;
    },
);



export const representationSelector = createSelector(
    [stateSelector, (state, name) => name, (state, name) => columnsGridSelector(state, name)],
    (state, gridName, columnList) => {
        const representationName = state.representation ? state.representation[gridName] ? state.representation[gridName] : state.representation[gridName] = { value: null, name: true} : {};
        const representation = representationName && state.list && Object.keys(state.list).length !== 0 ? state.list.find(el => el.id === representationName.value) : [];
        const actualRepresentation = [];

        representation && representation.config &&
        JSON.parse(representation.config).forEach(item => {
                const actualItem = columnList.find(column => column.name === item.name);
                if (actualItem) {
                    actualRepresentation.push({
                        ...actualItem,
                        width: item.width,
                        filter: item.filter,
                        sort: item.sort,
                    });
                }
            });
        return actualRepresentation;
    },
);



export const representationFromGridSelector = createSelector(
    [
        stateSelector,
        (state, name) => name,
        (state, name) => columnsGridSelector(state, name),
        (state, name) => representationSelector(state, name),
    ],
    (state, gridName, list, representation) => {
        if (representation && representation.length) {
            return representation;
        }
        return list.filter(item => item.isDefault);
    },
);

export const columnsTypesConfigSelector = createSelector(
    columnsGridSelector,
    columns => {
        let config = {};

        columns.forEach(column => {
            config = {
                ...config,
                [column.name]: {
                    type: column.name === 'soldTo' ? SOLD_TO_TYPE : column.type,
                    source: column.source,
                },
            };
        });

        return {
            ...config,
            orderCosts: {
                type: RETURN_COST_TYPE,
            },
        };
    },
);

//*  SAGA  *//

function* getRepresentationsSaga({ payload }) {
    try {
        const { key, callBackFunc } = payload;
        const result = yield postman.get(`/views/forSelect/${key}`);
        yield put({
            type: GET_REPRESENTATIONS_SUCCESS,
            payload: result ? result.sort((elA, elB) => {
                if (elA.name.toLowerCase() < elB.name.toLowerCase())
                  return -1
                if (elA.name.toLowerCase() > elB.name.toLowerCase())
                  return 1
                return 0
            }) : {},
        });
        const columns = yield select(state => representationFromGridSelector(state, key));
        
        callBackFunc && callBackFunc(columns);

    } catch (e) {
        yield put({
            type: GET_REPRESENTATIONS_ERROR,
            payload: e,
        });
    }
}


function* saveRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name, value } = payload;
        const list = yield select(representationsSelector);
        const representationValue = value.map(item => ({
            ...item,
        }));



        const result = yield postman.post(`/views/saveOrCreate`, {
            name: name,
            type: key,
            config: JSON.stringify(representationValue),
        });

        yield put({
            type: SAVE_REPRESENTATION_SUCCESS
        });


        callbackSuccess && callbackSuccess(result.id);
    } catch (e) {
        yield put({
            type: SAVE_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* editRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name, value } = payload;
        const representationValue = value.map(item => ({
            ...item,
        }));
        const currentUser = localStorage.getItem(CURRENT_USER_NAME);
        const currentId = JSON.parse(localStorage.getItem(REPRESENTATION_KEY))[currentUser][key].value;



        const result = yield postman.post(`/views/saveOrCreate`, {
            id: currentId,
            name: name,
            type: key,
            config: JSON.stringify(representationValue),
        });
        console.log(yield select(openSelector));
        yield put({
            type: EDIT_REPRESENTATION_SUCCESS
        });


        
        callbackSuccess && callbackSuccess(result.id);
    } catch (e) {
        yield put({
            type: EDIT_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}


function* deleteRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name } = payload;
        // const list = yield select(representationsSelector);
        const currentUser = localStorage.getItem(CURRENT_USER_NAME);
        const representation = JSON.parse(localStorage.getItem(REPRESENTATION_KEY))[currentUser];
        const repId = representation[key].value;
        let params = {};
        

        // Object.keys(list).forEach(keyList => {
        //     if (keyList !== name) {
        //         params = {
        //             ...params,
        //             [keyList]: list[keyList],
        //         };
        //     }
        // });

        

        yield postman.delete(`/views/delete/${repId}`);

        yield put({
            type: DELETE_REPRESENTATION_SUCCESS
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: DELETE_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* setRepresentationSaga({ payload }) {
    try {
        yield put(
            getRepresentationsRequest({
                key: payload.gridName,
            }),
        );
        const { callbackSuccess } = payload;
        const state = yield select(state => state.representations.representation);
        let userRepresentation = {};
        userRepresentation[localStorage.getItem(CURRENT_USER_NAME)] = state ? state : {};
        localStorage.setItem(REPRESENTATION_KEY, JSON.stringify(userRepresentation));

        setTimeout(() => {
            callbackSuccess && callbackSuccess();
        }, 500);
    } catch (e) {
        console.log('___error', e);
    }
}

export function* saga() {
    yield all([
        takeEvery(SAVE_REPRESENTATION_REQUEST, saveRepresentationSaga),
        takeEvery(EDIT_REPRESENTATION_REQUEST, editRepresentationSaga),
        takeEvery(DELETE_REPRESENTATION_REQUEST, deleteRepresentationSaga),
        takeEvery(SET_REPRESENTATION, setRepresentationSaga),
        takeEvery(GET_REPRESENTATIONS_REQUEST, getRepresentationsSaga),
    ]);
}
