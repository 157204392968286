import React from 'react'
import {useTranslation} from "react-i18next";
import {Button, Popup, Table} from "semantic-ui-react";
import FacetField from "../../../components/FilterComponents";
import {ORDERS_GRID} from "../../../constants/grids";
import CellValue from "../../../components/ColumnsValue";
import Icon from "../../../components/CustomIcon";
import Block from "../../../components/CardLayout/components/block";

const ReportBlock = ({ columns, report, sort, filter, setSort, setFilter, loading, clearFilter, exportExcel, exportProgress }) => {

    const { t } = useTranslation();

    const item = {
        menuItem: 'report',
        render: () => (
            <Table>
                <Table.Header>
                    <Table.Row>
                        {columns.map((column, i) => (
                            <Table.HeaderCell>
                                <FacetField
                                    key={'facet' + column.name}
                                    notWrapHeader
                                    index={i}
                                    gridName={ORDERS_GRID}
                                    name={column.name}
                                    displayNameKey={column.displayNameKey}
                                    sort={sort && sort.name === column.name ? sort.desc : null}
                                    setSort={setSort}
                                    type={column.type}
                                    value={filter[column.name]}
                                    filters={filter}
                                    setFilter={setFilter}
                                    source={column.source}
                                />
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {report && report.map((row, indexRow) => (
                        <Table.Row key={row.id}>
                            {columns.map((column, indexColumn) => (
                                <Table.Cell key={`${row.id}_${column.name}`}>
                                    <CellValue
                                        {...column}
                                        indexRow={indexRow}
                                        indexColumn={indexColumn}
                                        value={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].value
                                                : row[column.name]
                                        }
                                        valueText={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name][Object.keys(row[column.name]).find(key => key !== 'value')]
                                                : null
                                        }
                                        rowId={row.id}
                                        t={t}
                                    />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        ),
        actions: () => [
            <Popup
                content={t('exportToExcel')}
                position="bottom right"
                trigger={
                    <Button
                        icon="download"
                        disabled={!report.length}
                        loading={exportProgress}
                        onClick={exportExcel}
                    />
                }
            />,
            <Popup
                content={t('reset_filters')}
                position="bottom right"
                trigger={
                    <Button
                        icon
                        className={`clear-filter-btn`}
                        onClick={clearFilter}
                        disabled={!Object.keys(filter).length}
                    >
                        <Icon name="clear-filter" />
                    </Button>
                }
            />,
        ],
    };

    return <Block item={item} actions={item.actions} loading={loading} isFullScreen />
};

export default ReportBlock;
