import React from 'react'
import {numbersFormat} from "../../utils/numbersFormat";

const NumberValue = ({ decimals, value }) => {
    if (decimals !== undefined && decimals !== null) {
        return <>{new Intl.NumberFormat().format(numbersFormat(parseFloat(value).toFixed(decimals)))}</>; // new Intl.NumberFormat().format() чтоб разделитель дробной части брался из настроек системы)
    }
    return <>{new Intl.NumberFormat().format(numbersFormat(parseFloat(value)))}</>;
};

export default NumberValue;
