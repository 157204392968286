import React from 'react';
import {Button, Form, Grid} from 'semantic-ui-react';
import Date from '../../../components/BaseComponents/Date';
import CheckBox from '../../../components/BaseComponents/Checkbox';
import {useTranslation} from 'react-i18next';

const OperationalReportParams = ({ params, onChange, getReport, isDisabledBtn }) => {
    const { t } = useTranslation();

    return (
        <Form>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Date
                            name="startDate"
                            value={params.startDate}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Date name="endDate" value={params.endDate} onChange={onChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <CheckBox
                            name="deliveryType"
                            checked={params.deliveryType}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <CheckBox
                            name="client"
                            checked={params.client}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <CheckBox
                            name="daily"
                            checked={params.daily}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button primary disabled={isDisabledBtn} onClick={getReport}>
                            {t('Generate report')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default OperationalReportParams;
