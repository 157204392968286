import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearReport,
    columnsSelector,
    exportProgressSelector,
    getReportRequest,
    progressSelector,
    reportExportToExcelRequest,
    reportSelector,
} from '../../ducks/reports';
import { initialStateReport, OPERATIONAL_REPORT_TYPE } from '../../constants/reportType';
import Block from './components/Block';
import useReactRouter from 'use-react-router';
import Params from './components/Params';

const Report = () => {
    const dispatch = useDispatch();
    const { match } = useReactRouter();
    const { params: matchParams = {} } = match;
    const reportName = matchParams.type;

    const paramsRef = useRef(null);

    let [params, setParams] = useState(initialStateReport[match.params.type]);

    let [isDisabledBtn, setIsDisadledBtn] = useState(true);

    let [filter, setFilter] = useState({});
    let [sort, setSort] = useState({});

    let [heightParams, setHeightParams] = useState(0);

    const columns = useSelector(columnsSelector);
    const report = useSelector(reportSelector);
    const exportProgress = useSelector(exportProgressSelector);
    const loading = useSelector(progressSelector);

    useEffect(() => {
            getReport();
        return () => {
            dispatch(clearReport());
        }
    }, [filter, sort]);

    useEffect(() => {
        setHeightParams(paramsRef.current && paramsRef.current.offsetHeight);
    }, [paramsRef.current]);

    const mapData = () => {
        return {
            type: reportName,
            params: {
                ...params,
                filter,
                sort,
            },
        };
    };

    const getReport = () => {
        dispatch(getReportRequest(mapData()));
    };

    const handleChangeParams = (e, { name, value }) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        setParams(newParams);

        if (
            !isDisabledBtn &&
            reportName === OPERATIONAL_REPORT_TYPE &&
            (!newParams.deliveryType && !newParams.client && !newParams.daily)
        ) {
            setIsDisadledBtn(true);
        } else {
            setIsDisadledBtn(false);
        }
    };

    const handleChangeFilter = (e, { name, value }) => {
        setFilter(filter => {
            let newFilter = { ...filter };

            if (value) {
                newFilter = {
                    ...filter,
                    [name]: value,
                };
            } else {
                delete newFilter[name];
            }

            return newFilter;
        });
    };

    const clearFilter = () => {
        setFilter({});
    };

    const exportExcel = () => {
        dispatch(reportExportToExcelRequest(mapData()));
    };

    const generateReport = () => {
        setFilter({});
        setSort({});
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_params" ref={paramsRef}>
                    <Params
                        type={reportName}
                        params={params}
                        onChange={handleChangeParams}
                        getReport={generateReport}
                        isDisabledBtn={isDisabledBtn}
                    />
                </div>
                <div className="report_table">
                    <Block
                        columns={columns}
                        report={report}
                        loading={loading}
                        sort={sort}
                        filter={filter}
                        setSort={setSort}
                        clearFilter={clearFilter}
                        setFilter={handleChangeFilter}
                        exportExcel={exportExcel}
                        exportProgress={exportProgress}
                    />
                </div>
            </div>
            <style>
                {`\
            .card-content-block_open{\
              max-height: calc(100vh - ${heightParams}px - 100px - 76px) !important;\
            }\
        `}
            </style>
        </div>
    );
};

export default Report;
