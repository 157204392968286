import React from 'react';
import Field from '../../../../components/BaseComponents';


const FormField = (props) => {

    const { form = {}, columnsConfig = {}, error = {}, settings = {}, name } = props;

    return (
        <Field
            {...props}
            value={form[name]}
            type={columnsConfig[name] && columnsConfig[name].type}
            source={columnsConfig[name] && columnsConfig[name].source}
            error={error[name]}
            settings={settings[name]}
        />
    )
};

export default React.memo(FormField);
