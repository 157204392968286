import React from 'react';
import {KPI_REPORT_TYPE, OPERATIONAL_REPORT_TYPE} from '../../../constants/reportType';
import OperationalReportParams from './OperationalReportParams';
import KpiReportParams from "./KpiReportParams";

const Params = ({ type, ...props }) => {
    switch (type) {
        case OPERATIONAL_REPORT_TYPE:
            return <OperationalReportParams {...props} />;
        case KPI_REPORT_TYPE:
            return <KpiReportParams {...props} />;
        default:
            return null;
    }
};

export default Params;
