import React from 'react';

import {
    ACTIVE_TYPE,
    BOOLEAN_TYPE, CUSTOM_SELECT_TYPE, CUSTOM_STATE_TYPE,
    ENUM_TYPE, HIGHLIGHTED_NUMBER_TYPE,
    LABELS_TYPE,
    LINK_TYPE,
    LOCAL_DATE_TIME,
    NUMBER_TYPE,
    SELECT_TYPE,
    STATE_TYPE,
} from '../../constants/columnTypes';
import { numbersFormat } from '../../utils/numbersFormat';
import { Checkbox, Label } from 'semantic-ui-react';
import StateValue from './StateValue';
import SelectValue from './SelectValue';
import TextCropping from './TextCropping';
import { dateToUTC } from '../../utils/dateTimeFormater';
import ToggleCheckbox from './ToogleCheckbox';
import NumberValue from "./NumberValue";

const CellValue = ({
    type,
    value = '',
    valueText,
    id,
    toggleIsActive,
    source,
    indexRow,
    indexColumn,
    modalCard,
    showRawValue,
    width,
    t,
    isDisabled,
    cardLink,
    gridName,
    rowId,
    goToCard,
    decimals,
}) => {
    if (type === SELECT_TYPE || type === CUSTOM_SELECT_TYPE) {
        return (
            <SelectValue
                width={width}
                value={value}
                valueText={valueText}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
                showRawValue={showRawValue}
            />
        );
    }

    if (type === STATE_TYPE || type === CUSTOM_STATE_TYPE) {
        return (
            <StateValue
                width={width}
                value={value}
                source={source}
                indexRow={indexRow}
                indexColumn={indexColumn}
            />
        );
    }

    if (type === LABELS_TYPE) {
        return (
            <>
                {!value
                    ? t('All')
                    : value.map((n, i) => (
                          <Label key={n.name} className="label-margin">
                              {t(n.name)}
                          </Label>
                      ))}
            </>
        );
    }

    if (type === ENUM_TYPE) {
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {value ? valueText : ''}
            </TextCropping>
        );
    }

    if (type === ACTIVE_TYPE) {
        return (
            <ToggleCheckbox
                id={id}
                value={value}
                disabled={isDisabled}
                toggleIsActive={toggleIsActive}
            />
        );
    }

    if (type === BOOLEAN_TYPE) {
        return <>{value === true ? t('Yes') : value === false ? t('No') : ''}</>;
    }

    if (value === undefined || value === null) return '';

    if (type === NUMBER_TYPE) {
        return <NumberValue value={value} decimals={decimals}/>
    }

    if (type === HIGHLIGHTED_NUMBER_TYPE) {

        return (
            <div className={valueText ? 'highlighted_cell' : ''}>
                <NumberValue value={value} decimals={decimals} />
            </div>
        );
    }

    if (type === LINK_TYPE) {
        const handleGoToCard = () => {
            goToCard(true, rowId, source);
        };

        return (
            <>
                {goToCard ? (
                    <div className="link-cell" onClick={handleGoToCard}>
                        <TextCropping width={width} indexColumn={indexColumn}>
                            {value}
                        </TextCropping>
                    </div>
                ) : (
                    <TextCropping width={width} indexColumn={indexColumn}>
                        {value}
                    </TextCropping>
                )}
            </>
        );
    }

    if (type === LOCAL_DATE_TIME) {
        return (
            <TextCropping width={width} indexColumn={indexColumn}>
                {dateToUTC(value, 'DD.MM.YYYY HH:mm')}
            </TextCropping>
        );
    }

    return (
        <TextCropping width={width} indexColumn={indexColumn}>
            {value}
        </TextCropping>
    );
};

export default React.memo(CellValue);
