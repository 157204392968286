import {
    all,
    put,
    takeEvery,
    take,
    spawn,
    delay,
    cancelled,
    cancel,
    fork,
    select,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import downloadFile from '../utils/downloadFile';
import { KPI_INDICATORS_REPORT_TYPE } from '../constants/reportType';

const TYPE_API = 'reports';

//*  TYPES  *//

const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

const REPORT_EXPORT_TO_EXCEL_REQUEST = 'REPORT_EXPORT_TO_EXCEL_REQUEST';
const REPORT_EXPORT_TO_EXCEL_SUCCESS = 'REPORT_EXPORT_TO_EXCEL_SUCCESS';
const REPORT_EXPORT_TO_EXCEL_ERROR = 'REPORT_EXPORT_TO_EXCEL_ERROR';

const SAVE_REPORT_SETTINGS_REQUEST = 'SAVE_REPORT_SETTINGS_REQUEST';
const SAVE_REPORT_SETTINGS_SUCCESS = 'SAVE_REPORT_SETTINGS_SUCCESS';
const SAVE_REPORT_SETTINGS_ERROR = 'SAVE_REPORT_SETTINGS_ERROR';

const CLEAR_REPORT = 'CLEAR_REPORT';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    columns: [],
    config: [],
    items: [],
    progress: false,
    exportProgress: false,
    saveProgress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_REPORT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                progress: false,
                ...payload,
            };
        case GET_REPORT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case REPORT_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true,
            };
        case REPORT_EXPORT_TO_EXCEL_SUCCESS:
        case REPORT_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false,
            };
        case CLEAR_REPORT:
            return {
                ...state,
                ...initial,
            };
        case SAVE_REPORT_SETTINGS_REQUEST:
            return {
                ...state,
                saveProgress: true
            };
        case SAVE_REPORT_SETTINGS_SUCCESS:
        case SAVE_REPORT_SETTINGS_ERROR:
            return {
                ...state,
                saveProgress: false
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getReportRequest = payload => {
    return {
        type: GET_REPORT_REQUEST,
        payload,
    };
};

export const reportExportToExcelRequest = payload => {
    return {
        type: REPORT_EXPORT_TO_EXCEL_REQUEST,
        payload,
    };
};

export const clearReport = () => {
    return {
        type: CLEAR_REPORT,
    };
};

export const saveReportSettingsRequest = payload => {
    return {
        type: SAVE_REPORT_SETTINGS_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.reports;
export const reportSelector = createSelector(
    stateSelector,
    state => (state.data && state.data.length ? state.data : state.items),
);

export const columnsSelector = createSelector(
    stateSelector,
    state => {
        return state.columns && state.columns.length
            ? state.config.filter(item =>
                  state.columns
                      .map(column => column.toLowerCase())
                      .includes(item.name.toLowerCase()),
              )
            : state.config;
    },
);

export const progressSelector = createSelector(
    stateSelector,
    state => state.progress,
);
export const exportProgressSelector = createSelector(
    stateSelector,
    state => state.exportProgress,
);

export const saveProgressSelector = createSelector(
    stateSelector,
    state => state.saveProgress,
);

//*  SAGA  *//

function* getReportSaga({ payload }) {
    try {
        const { type, params } = payload;

        const config = yield postman.get(`/${TYPE_API}/${type}/reportConfiguration`);

        const result = yield postman[type === KPI_INDICATORS_REPORT_TYPE ? 'get' : 'post'](
            `/${TYPE_API}/${type}/get`,
            params,
        );

        yield put({
            type: GET_REPORT_SUCCESS,
            payload:
                type === KPI_INDICATORS_REPORT_TYPE
                    ? {
                          data: [result],
                          config: config.columns,
                      }
                    : {
                          ...result,
                          config: config.columns,
                      },
        });
    } catch (e) {
        yield put({
            type: GET_REPORT_ERROR,
        });
    }
}

function* reportExportToExcelSaga({ payload }) {
    try {
        const { type, params } = payload;

        const result = yield downloader.post(`/${TYPE_API}/${type}/export`, params, {
            responseType: 'blob',
        });

        downloadFile(result);

        yield put({ type: REPORT_EXPORT_TO_EXCEL_SUCCESS });
    } catch (e) {
        yield put({
            type: REPORT_EXPORT_TO_EXCEL_ERROR,
        });
    }
}

function* saveReportSettingsSaga({ payload }) {
    try {
        const { type, params, callbackSuccess } = payload;

        const result = yield postman.post(`/${TYPE_API}/${type}/save`, params);

        callbackSuccess && callbackSuccess();

        yield put({ type: SAVE_REPORT_SETTINGS_SUCCESS });
    } catch (e) {
        yield put({
            type: SAVE_REPORT_SETTINGS_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_REPORT_REQUEST, getReportSaga),
        takeEvery(REPORT_EXPORT_TO_EXCEL_REQUEST, reportExportToExcelSaga),
        takeEvery(SAVE_REPORT_SETTINGS_REQUEST, saveReportSettingsSaga),
    ]);
}
