import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    previewColumnsSelector,
    previewOrdersSelector,
    previewSelector,
} from '../../../../ducks/autogrouping';
import { Table } from 'semantic-ui-react';
import CellValue from '../../../../components/ColumnsValue';
import { ORDERS_GRID } from '../../../../constants/grids';
import FacetField from '../../../../components/FilterComponents';
import CustomCheckbox from '../../../../components/BaseComponents/CustomCheckbox';
import Filter from '../../../../components/SuperGrid/components/filter';
import { Resizable } from 'react-resizable';

const Preview = ({
    selectedRows,
    setSelectedAll,
    handleCheck,
    columns: columnsArray,
    preview,
    filter,
    changeFilter,
    width,
    full,
    gridFilter,
}) => {
    const { t } = useTranslation();

    let [columns, setColumns] = useState([...columnsArray]);
    let [widthFull, setWidth] = useState(0);
    let [extWidth, setExtWidth] = useState(0);

    useEffect(
        () => {
            setColumns(columns =>
                columns.map(item => ({
                    ...item,
                    width: parseInt((width - 65) / columns.length),
                })),
            );

            setWidth(width);
        },
        [width],
    );

    const handleResize = (e, { size, index }) => {
        setColumns(columns => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return nextColumns;
        });

        let sum = 0;

        columns.forEach(item => {
            sum = sum + item.width;
        });

        setExtWidth(width - 50 - sum);
        setWidth(sum + 50);
    };

    return (
        <div style={{ height: !full ? '400px' : '100%', overflow: 'auto' }}>
            <Table
                basic="very"
                className="grid-table"
                style={{ minWidth: widthFull }}
                fixed
                unstackable
            >
                <Table.Header>
                    <Table.Row className="sticky-header">
                        <Table.HeaderCell className="small-column" style={{ paddingLeft: '12px' }}>
                            <CustomCheckbox
                                indeterminate={
                                    !!(
                                        selectedRows.length &&
                                        selectedRows.length !== preview.length
                                    )
                                }
                                checked={
                                    !!(
                                        selectedRows.length &&
                                        selectedRows.length === preview.length
                                    )
                                }
                                multi
                                onChange={setSelectedAll}
                            />
                        </Table.HeaderCell>
                        {columns.map((column, index) => (
                            <Resizable
                                key={`resizable_${column.name}`}
                                width={column.width}
                                height={0}
                                axis="x"
                                onResize={(e, { size }) => handleResize(e, { size, index })}
                            >
                                <Table.HeaderCell
                                    key={column.name}
                                    style={{ width: `${column.width}px` }}
                                    className={`column-facet column-${column.name &&
                                        column.name.toLowerCase().replace(' ', '-')}-facet`}
                                >
                                    <FacetField
                                        key={'facet' + column.name}
                                        index={index}
                                        name={column.name}
                                        gridName={ORDERS_GRID}
                                        displayNameKey={column.displayNameKey}
                                        noSort
                                        width={column.width}
                                        type={column.type}
                                        value={filter[column.name]}
                                        filters={{ filter: { ...gridFilter.filter, ...filter } }}
                                        setFilter={changeFilter}
                                        source={column.source}
                                    />
                                </Table.HeaderCell>
                            </Resizable>
                        ))}
                        <Table.HeaderCell style={{ width: extWidth > 0 ? extWidth : 0 }} />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {preview.map((row, indexRow) => (
                        <Table.Row
                            key={row.orderId}
                            className={`grid-row${
                                selectedRows.includes(row.orderId) ? ' grid-row-selected' : ''
                            }`}
                        >
                            <Table.Cell
                                key={row.id + 'checkbox'}
                                style={{ paddingLeft: '12px' }}
                                className="small-column"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <CustomCheckbox
                                    checked={!!selectedRows.includes(row.orderId)}
                                    onChange={() => {
                                        handleCheck(row);
                                    }}
                                />
                            </Table.Cell>
                            {columns.map((column, indexColumn) => (
                                <Table.Cell
                                    key={`cell_${row.id}_${column.name}`}
                                    className="value-cell"
                                >
                                    <div className="cell-grid">
                                        <div
                                            className={`cell-grid-value${
                                                row[column.name] ? '' : ' cell-grid-value_empty'
                                            }`}
                                        >
                                            <CellValue
                                                {...column}
                                                indexRow={indexRow}
                                                indexColumn={indexColumn}
                                                value={
                                                    row[column.name] &&
                                                    typeof row[column.name] === 'object'
                                                        ? row[column.name].value
                                                        : row[column.name]
                                                }
                                                valueText={
                                                    row[column.name] &&
                                                    typeof row[column.name] === 'object'
                                                        ? row[column.name].name
                                                        : null
                                                }
                                                width={column.width}
                                                gridName={ORDERS_GRID}
                                                t={t}
                                            />
                                        </div>
                                    </div>
                                </Table.Cell>
                            ))}
                            <Table.Cell />
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default Preview;
