import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import { all, delay, put, takeEvery, select, fork } from 'redux-saga/effects';
import { ORDERS_GRID } from '../constants/grids';
import { columnsGridSelector } from './gridList';
import downloadFile from '../utils/downloadFile';
import {
    BIG_TEXT_TYPE,
    CUSTOM_SELECT_TYPE,
    CUSTOM_STATE_TYPE, DATE_TYPE, ENUM_TYPE,
    INTEGER_TYPE,
    LINK_TYPE,
    NUMBER_TYPE,
    SELECT_TYPE,
    STATE_TYPE,
    TEXT_TYPE,
} from '../constants/columnTypes';
import {isRange} from "../utils/dateTimeFormater";

const TYPE_API = 'autogrouping';

//*  TYPES  *//

const GET_GROUP_FIELDS_REQUEST = 'GET_GROUP_FIELDS_REQUEST';
const GET_GROUP_FIELDS_SUCCESS = 'GET_GROUP_FIELDS_SUCCESS';
const GET_GROUP_FIELDS_ERROR = 'GET_GROUP_FIELDS_ERROR';

const PREVIEW_AUTO_GROUPING_REQUEST = 'PREVIEW_AUTO_GROUPING_REQUEST';
const PREVIEW_AUTO_GROUPING_SUCCESS = 'PREVIEW_AUTO_GROUPING_SUCCESS';
const PREVIEW_AUTO_GROUPING_ERROR = 'PREVIEW_AUTO_GROUPING_ERROR';

const PREVIEW_EXPORT_TO_EXCEL_REQUEST = 'PREVIEW_EXPORT_TO_EXCEL_REQUEST';
const PREVIEW_EXPORT_TO_EXCEL_SUCCESS = 'PREVIEW_EXPORT_TO_EXCEL_SUCCESS';
const PREVIEW_EXPORT_TO_EXCEL_ERROR = 'PREVIEW_EXPORT_TO_EXCEL_ERROR';

const APPLY_AUTO_GROUPING_REQUEST = 'APPLY_AUTO_GROUPING_REQUEST';
const APPLY_AUTO_GROUPING_SUCCESS = 'APPLY_AUTO_GROUPING_SUCCESS';
const APPLY_AUTO_GROUPING_ERROR = 'APPLY_AUTO_GROUPING_ERROR';

const GET_CONFIG = 'GET_CONFIG';

const CHANGE_IS_GROUPING = 'CHANGE_IS_GROUPING';

//*  INITIAL STATE  *//

const initial = {
    groupFields: [],
    getGroupFieldsProgress: false,
    preview: {},
    previewProgress: false,
    exportProgress: false,
    result: {},
    config: {},
    applyProgress: false,
    isGrouping: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_GROUP_FIELDS_REQUEST:
            return {
                ...state,
                getGroupFieldsProgress: true,
            };
        case GET_GROUP_FIELDS_SUCCESS:
            return {
                ...state,
                groupFields: payload,
                getGroupFieldsProgress: false,
            };
        case GET_GROUP_FIELDS_ERROR:
            return {
                ...state,
                groupFields: [],
                getGroupFieldsProgress: false,
            };
        case PREVIEW_AUTO_GROUPING_REQUEST:
            return {
                ...state,
                isGrouping: false,
                previewProgress: true,
            };
        case PREVIEW_AUTO_GROUPING_SUCCESS:
            return {
                ...state,
                preview: payload,
                isGrouping: true,
                previewProgress: false,
            };
        case PREVIEW_AUTO_GROUPING_ERROR:
            return {
                ...state,
                preview: [],
                isGrouping: false,
                previewProgress: false,
            };
        case PREVIEW_EXPORT_TO_EXCEL_REQUEST:
            return {
                ...state,
                exportProgress: true,
            };
        case PREVIEW_EXPORT_TO_EXCEL_SUCCESS:
        case PREVIEW_EXPORT_TO_EXCEL_ERROR:
            return {
                ...state,
                exportProgress: false,
            };
        case APPLY_AUTO_GROUPING_REQUEST:
            return {
                ...state,
                applyProgress: true,
            };
        case APPLY_AUTO_GROUPING_SUCCESS:
            return {
                ...state,
                applyProgress: false,
                result: payload,
            };
        case APPLY_AUTO_GROUPING_ERROR:
            return {
                ...state,
                applyProgress: false,
                result: {},
            };
        case CHANGE_IS_GROUPING:
            return {
                ...state,
                isGrouping: payload,
                preview: {},
                result: {},
            };
        case GET_CONFIG:
            return {
                ...state,
                config: payload
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export const getGroupFieldsRequest = payload => {
    return {
        type: GET_GROUP_FIELDS_REQUEST,
        payload,
    };
};

export const previewAutoGroupingRequest = payload => {
    return {
        type: PREVIEW_AUTO_GROUPING_REQUEST,
        payload,
    };
};

export const previewExportToExcelRequest = payload => {
    return {
        type: PREVIEW_EXPORT_TO_EXCEL_REQUEST,
        payload,
    };
};

export const applyAutoGroupingRequest = payload => {
    return {
        type: APPLY_AUTO_GROUPING_REQUEST,
        payload,
    };
};

export const changeIsGrouping = payload => {
    return {
        type: CHANGE_IS_GROUPING,
        payload,
    };
};

//*  SELECTORS *//
const stateSelector = state => state.autogrouping;

export const groupFieldsSelector = createSelector(stateSelector, state => state.groupFields);
export const previewSelector = createSelector(stateSelector, state => state.preview);
export const previewOrdersSelector = createSelector(
    [stateSelector, (state, filter) => filter, (state, filter, columns) => columns],
    (state, filter, colunms) => {
        let result = state.preview.orders || [];
        if (!filter) return result;

        Object.keys(filter).forEach(key => {
            const column = colunms.find(item => item.name === key);
            const type = column && column.type;

            if(filter[key]) {
                if ([LINK_TYPE, TEXT_TYPE, BIG_TEXT_TYPE, INTEGER_TYPE, NUMBER_TYPE].includes(type)) {
                    result = result.filter(item =>
                        item[key] && item[key]
                            .toString()
                            .toLowerCase()
                            .includes(filter[key].toString().toLowerCase()),
                    );
                }

                if (type === STATE_TYPE || type === ENUM_TYPE) {
                    const filterArray = filter[key].split('|');
                    result = result.filter(item => filterArray.includes(item[key] && item[key].value));
                }

                if (type === SELECT_TYPE) {
                    const filterArray = filter[key].split('|');
                    result = result.filter(item => filterArray.includes(item[key] && item[key].value));
                }

                if (type === DATE_TYPE) {
                    const dateArray = filter[key].split('-');
                    result = result.filter(item => isRange(dateArray[0], dateArray[1] || dateArray[0], item[key]));
                }
            }
        });

        return result;
    },
);
export const previewColumnsSelector = createSelector(stateSelector, state => state.config.columns || []);
export const isGroupingSelector = createSelector(stateSelector, state => state && state.isGrouping);

export const resultSelector = createSelector(stateSelector, state => state.result.entries);
export const resultMessageSelector = createSelector(stateSelector, state => state.result.message);

export const previewProgressSelector = createSelector(
    stateSelector,
    state => state.previewProgress,
);
export const exportProgressSelector = createSelector(stateSelector, state => state.exportProgress);
export const applyProgressSelector = createSelector(stateSelector, state => state.applyProgress);

//*  SAGA  *//

function* getGroupFieldsSaga({ payload }) {
    try {
        const { callBackSuccess } = payload;
        const result = yield postman.get(`/${TYPE_API}/groupFields`);

        yield put({
            type: GET_GROUP_FIELDS_SUCCESS,
            payload: result,
        });

        callBackSuccess && callBackSuccess(result);
    } catch (e) {
        yield put({
            type: GET_GROUP_FIELDS_ERROR,
        });
    }
}

function* getConfig() {
    try {
        const result = yield postman.get(`/${TYPE_API}/previewConfiguration`);

        yield put({
            type: GET_CONFIG,
            payload: result
        })
    } catch (e) {
        console.log('error')
    }
}

function* previewAutoGroupSaga({ payload }) {
    try {
        const { params, callBackSuccess } = payload;
        yield fork(getConfig);
        const result = yield postman.post(`/${TYPE_API}/preview`, params);

        yield put({
            type: PREVIEW_AUTO_GROUPING_SUCCESS,
            payload: result,
        });

        callBackSuccess && callBackSuccess(result.orders);
    } catch (e) {
        yield put({
            type: PREVIEW_AUTO_GROUPING_ERROR,
        });
    }
}

function* previewExportToExcelSaga({ payload }) {
    try {
        const preview = yield select(previewSelector);
        const result = yield downloader.post(`/${TYPE_API}/exportToExcel`, preview, {
            responseType: 'blob',
        });

        downloadFile(result);

        yield put({
            type: PREVIEW_EXPORT_TO_EXCEL_SUCCESS,
        });
    } catch (e) {
        yield put({
            type: PREVIEW_EXPORT_TO_EXCEL_ERROR,
        });
    }
}

function* applyAutoGroupingSaga({ payload }) {
    try {
        const result = yield postman.post(`/${TYPE_API}/apply`, payload);

        yield put({
            type: APPLY_AUTO_GROUPING_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: APPLY_AUTO_GROUPING_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_GROUP_FIELDS_REQUEST, getGroupFieldsSaga),
        takeEvery(PREVIEW_AUTO_GROUPING_REQUEST, previewAutoGroupSaga),
        takeEvery(PREVIEW_EXPORT_TO_EXCEL_REQUEST, previewExportToExcelSaga),
        takeEvery(APPLY_AUTO_GROUPING_REQUEST, applyAutoGroupingSaga),
    ]);
}
