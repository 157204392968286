import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Grid} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import {formatDate, parseDate} from '../../../utils/dateTimeFormater';

const KpiReportParams = ({params, onChange, getReport, isDisabledBtn}) => {
    const { t } = useTranslation();

    return (
        <Form>
            <Grid verticalAlign="bottom">
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('start')}</label>
                            <DatePicker
                                selected={parseDate((params.startDate || '').substring(0, 10))}
                                dateFormat="MM.yyyy"
                                locale="ru"
                                maxDate={parseDate((params.endDate || '').substring(0, 10))}
                                showMonthYearPicker
                                onChange={(date, e) =>
                                    onChange(e, {name: 'startDate', value: date ? formatDate(date) : null})
                                }
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('end')}</label>
                            <DatePicker
                                selected={parseDate((params.endDate || '').substring(0, 10))}
                                dateFormat="MM.yyyy"
                                locale="ru"
                                minDate={parseDate((params.startDate || '').substring(0, 10))}
                                showMonthYearPicker
                                onChange={(date, e) =>
                                    onChange(e, {name: 'endDate', value: date ? formatDate(date) : null})
                                }
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button primary disabled={isDisabledBtn} onClick={getReport}>
                            {t('Generate report')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default KpiReportParams;
