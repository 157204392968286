import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    clearReport,
    columnsSelector,
    getReportRequest,
    progressSelector,
    reportSelector, saveProgressSelector,
    saveReportSettingsRequest
} from "../../ducks/reports";
import {KPI_INDICATORS_REPORT_TYPE} from "../../constants/reportType";
import {Button, Form, Grid} from "semantic-ui-react";
import FormField from "../../components/BaseComponents";
import CreateOrder from "../customGrid/components/orderTabs/createOrder";
import CardLayout from "../../components/CardLayout";
import {useTranslation} from "react-i18next";

const KpiIndicatorsSettings = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    let [params, setParams] = useState({});
    let [hashChange, setHashChange] = useState(false);

    const columns = useSelector(columnsSelector);
   // const rowColumns = [[columns[0], columns[1]], [columns[2], columns[3]], [columns[4], columns[5]]];
    const report = useSelector(reportSelector) || [];
    const loading = useSelector(progressSelector);
    const saveLoading = useSelector(saveProgressSelector);

    useEffect(() => {
        getReport();
        return () => {
            dispatch(clearReport());
        }
    }, []);

    useEffect(() => {
        setParams(report[0] || {});
    }, [report[0]]);

    const getReport = () => {
        dispatch(getReportRequest({
            type: KPI_INDICATORS_REPORT_TYPE,
        }));
    };

    const handleSave = () => {
        dispatch(saveReportSettingsRequest({
            type: KPI_INDICATORS_REPORT_TYPE,
            params,
            callbackSuccess: getReport
        }))
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button
                        color="blue"
                        disabled={!hashChange}
                        loading={saveLoading}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [params, hashChange, saveLoading],
    );

    const handleChange = (e, {name, value}) => {
        setParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));

        setHashChange(true);
    };

    //console.log('rowColumns', rowColumns);

    return (
        <CardLayout
            title={t('Permission.EditKpiIndicators')}
            actionsFooter={getActionsFooter}
            loading={loading}
            isNotBack
        >
                <div className="ui form report-edit">
                    {columns.map(field => {
                        return (
                            <FormField
                                {...field}
                                key={field.name}
                                value={params[field.name]}
                                onChange={handleChange}
                            />
                        );
                    })}
                </div>
           {/* <Form>
                <Grid>
                    {
                        rowColumns.map(row => (
                            <Grid.Row columns={row.length}>
                                {
                                    row.map((field = {}) => (
                                            <Grid.Column key={field.name}>
                                                {
                                                    field && <FormField
                                                        {...field}
                                                        value={params[field.name]}
                                                        onChange={handleChange}
                                                    />
                                                }

                                            </Grid.Column>
                                        )
                                    )
                                }
                            </Grid.Row>
                        ))
                    }
                </Grid>
            </Form>*/}
        </CardLayout>
    )
};

export default KpiIndicatorsSettings;
