import {dateToString} from "../utils/dateTimeFormater";

export const OPERATIONAL_REPORT_TYPE = 'operational';
export const KPI_REPORT_TYPE = 'kpi';
export const KPI_INDICATORS_REPORT_TYPE = 'kpi/indicators';

export const initialStateReport = {
    [OPERATIONAL_REPORT_TYPE]: {
        startDate: dateToString(),
        endDate: dateToString(),
        deliveryType: true,
        client: true,
        daily: false,
    },
    [KPI_REPORT_TYPE]: {
        startDate: dateToString(),
        endDate: dateToString(),
    }
}
