import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/CardLayout';
import {Button, Checkbox, Form, Grid, Popup} from 'semantic-ui-react';
import {
    applyAutoGroupingRequest, applyProgressSelector,
    changeIsGrouping, exportProgressSelector,
    getGroupFieldsRequest,
    groupFieldsSelector,
    isGroupingSelector,
    previewAutoGroupingRequest, previewColumnsSelector,
    previewExportToExcelRequest,
    previewOrdersSelector,
    previewProgressSelector,
    resultMessageSelector,
    resultSelector,
} from '../../ducks/autogrouping';
import CheckBox from '../../components/BaseComponents/Checkbox';
import FormField from '../../components/BaseComponents';
import Preview from './components/autoGroupingTabs/preview';
import InfoComponent from '../../components/ModalInfo/InfoComponent';
import Icon from "../../components/CustomIcon";

const AutoGrouping = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { location, history } = props;
    const { state } = location;
    const { pathname, filter } = state;

    let [params, setParams] = useState([]);

    let [selectedRows, setSelectedRows] = useState([]);
    let [filters, setFilters] = useState({});
    let [isApply, setIsApply] = useState(false);

    const loading = false;
    const exportLoader = false;
    const title = 'Автогруппировка заказов';
    const columns = useSelector(previewColumnsSelector);
    const preview = useSelector(state => previewOrdersSelector(state, filters, columns));
    const groupFields = useSelector(groupFieldsSelector) || [];
    const isGrouping = useSelector(isGroupingSelector);
    const result = useSelector(resultSelector) || [];
    const resultMsg = useSelector(resultMessageSelector) || [];
    const previewProgress = useSelector(previewProgressSelector);
    const exportProgress = useSelector(exportProgressSelector);
    const applyProgress = useSelector(applyProgressSelector);

    useEffect(() => {
        dispatch(
            getGroupFieldsRequest({
                callBackSuccess: params => {
                    setParams(params.filter(item => item.isDefault).map(item => item.value));
                },
            }),
        );

        return () => dispatch(changeIsGrouping(false));
    }, []);

    useEffect(() => {
        const idsArray = preview.map(item => item.orderId);

        setSelectedRows(selectedRows.filter(item => idsArray.includes(item)));

    }, [preview]);

    useEffect(() => {
        setIsApply(false);
    }, [selectedRows]);

    const handleChangeFilter = (e, { name, value }) => {
        setFilters(filter => {
            let newFilter = {...filter};

            if (value) {
                newFilter = {
                    ...filter,
                    [name]: value
                }
            } else {
               delete newFilter[name]
            }

            return newFilter;
        })
    };


    const clearFilter = () => {
        setFilters({})
    };

    const handleChangeParams = (e, { value, name }) => {
        let newParams = new Set(params);

        newParams[newParams.has(name) ? 'delete' : 'add'](name);

        setParams(Array.from(newParams));

        if (isGrouping) {
            dispatch(changeIsGrouping(false));
        }
    };

    const handleGrouping = () => {
        dispatch(
            previewAutoGroupingRequest({
                params: {
                    filter: { ...filter.filter.filter },
                    groupFields: params,
                },
                callBackSuccess: preview => {
                    setSelectedRows(preview.map(item => item.orderId));
                    setIsApply(false);
                },
            }),
        );
    };

    const handleApply = () => {
        const params = preview.map(item => ({
            ...item,
            isChecked: selectedRows.includes(item.orderId),
        }));

        dispatch(applyAutoGroupingRequest({ orders: params }));
        setIsApply(true);
    };

    const exportExcel = () => {
        dispatch(previewExportToExcelRequest());
    };

    const setSelectedAll = () => {
        if (selectedRows.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(preview.map(item => item.orderId));
        }
    };

    const handleCheck = row => {
        let newSelectedRows = new Set(selectedRows);

        newSelectedRows[!newSelectedRows.has(row.orderId) ? 'add' : 'delete'](row.orderId);
        setSelectedRows(Array.from(newSelectedRows));
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <div>
                    <Button
                        color="green"
                        disabled={isGrouping}
                        loading={previewProgress}
                        onClick={handleGrouping}
                    >
                        {t('Сгруппировать')}
                    </Button>
                    <div>
                        <Button color="grey" onClick={handleClose}>
                            {t('CancelButton')}
                        </Button>
                        <Button
                            color="blue"
                            loading={applyProgress}
                            disabled={!isGrouping || !preview.length || isApply}
                            onClick={handleApply}
                        >
                            {t('Применить')}
                        </Button>
                    </div>
                </div>
            );
        },
        [handleGrouping, isGrouping, previewProgress, applyProgress],
    );

    const getPanes = () => {
        let obj = [
            {
                menuItem: 'Параметры',
                render: () => (
                    <Form className="tabs-card">
                        {groupFields.map(item => (
                            <Form.Field>
                                <CheckBox
                                    checked={params.includes(item.value)}
                                    label={t(item.name)}
                                    value={item.value}
                                    name={item.value}
                                    onChange={handleChangeParams}
                                />
                            </Form.Field>
                        ))}
                    </Form>
                ),
            },
            {
                menuItem: 'Предварительный просмотр',
                disabled: !isGrouping,
                isFullScreen: true,
                subTitle: isGrouping ? t('totalCount', { count: preview.length }) : '',
                actions: () => [
                    <Popup
                        content={t('exportToExcel')}
                        position="bottom right"
                        trigger={
                            <Button icon="download" loading={exportProgress} onClick={exportExcel} />
                        }
                    />,
                    <Popup
                        content={t('reset_filters')}
                        position="bottom right"
                        trigger={
                            <Button
                                icon
                                className={`clear-filter-btn`}
                                onClick={clearFilter}
                                disabled={!Object.keys(filters).length}
                            >
                                <Icon name="clear-filter" />
                            </Button>
                        }
                    />
                ],
                render: (width, full) => (
                    <>
                        {isGrouping && !preview.length && !Object.keys(filters).length ? (
                            <div
                                style={{
                                    padding: '12px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Не обнаружено заказов для автоматической группировки
                            </div>
                        ) : columns.length ? (
                            <Preview
                                width={width}
                                full={full}
                                columns={columns}
                                preview={preview}
                                filter={filters}
                                gridFilter={filter}
                                changeFilter={handleChangeFilter}
                                selectedRows={selectedRows}
                                setSelectedAll={setSelectedAll}
                                handleCheck={handleCheck}
                            />
                        ) : null}
                    </>
                ),
            },
            {
                menuItem: 'Результат',
                disabled: !result.length,
                subTitle: resultMsg,
                render: () => (
                    <>
                        {result.length ? (
                            <div style={{ padding: '12px' }}>
                                {result.map(item => (
                                    <InfoComponent item={item} />
                                ))}
                            </div>
                        ) : null}
                    </>
                ),
            },
        ];

        return obj;
    };

    const handleClose = () => {
        history.push({
            pathname,
            state: filter.filter,
        });
    };

    return (
        <>
            <CardLayout
                title={title}
                actionsFooter={getActionsFooter}
                content={getPanes}
                onClose={handleClose}
                loading={loading}
            />
        </>
    );
};

export default AutoGrouping;
